










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BaseMenuCard extends Vue {
  @Prop() readonly disabled!: boolean;
}
