














































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import BaseMenuCard from '@/components/BaseMenuCard.vue';
import DialogConnector from '@/connector/Dialog.vue';
import Auth from '@/connector/Auth.vue';
import ProfileConnector from '@/connector/Profile.vue';
// import SpinWheel from '@/components/SpinWheel.vue';

@Component({
  components: {
    BaseMenuCard,
    DialogConnector,
    ProfileConnector,
    ProfileDialog: () => import('@/components/ProfileDialog.vue'),
    ChangePasswordDialog: () => import('@/components/ChangePasswordDialog.vue'),
    TransactionDialog: () => import('@/components/TransactionDialog.vue'),
    BetHistoryDialog: () => import('@/components/BetHistoryDialog.vue'),
    RspGameDialog: () => import('@/components/RpsGameDialog.vue'),
    FortuneWheelDialog: () => import('@/components/FortuneWheelDialog.vue'),
    Auth,
  },
})
export default class DashboardPage extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get menus(): Array<{ name: string; link: string; icon: string; dialogName?: string }> {
    return [
      {
        name: 'เล่นเกมส์',
        link: '',
        icon: 'fas fa-money-bill-alt',
      },
      {
        name: 'บันทึกรายการ',
        link: '',
        icon: 'fas fa-list',
        dialogName: 'TransactionDialog',
      },
      {
        name: 'บันทึกยอดได้เสีย',
        link: '',
        icon: 'fas fa-clipboard',
        dialogName: 'BetHistoryDialog',
      },
      { name: 'แนะนำเพื่อน', link: '/commission', icon: 'fas fa-users' },
      {
        name: 'ข้อมูลส่วนตัว',
        link: '',
        icon: 'fas fa-user',
        dialogName: 'ProfileDialog',
      },
      {
        name: 'รหัสผ่าน',
        link: '',
        icon: 'fas fa-key',
        dialogName: 'ChangePasswordDialog',
      },
      { name: 'ออกจากระบบ', link: '', icon: 'fas fa-sign-out-alt' },
    ];
  }

  notifyPlayerToTopUp() {
    this.$store.commit('Snackbar/showNotification', { message: 'กรุณาเติมเงินเพื่อเล่นเกม', error: true });
  }

  playGame(urlObject: { desktopGameUrl: string; mobileGameUrl: string }): void {
    if (this.$vuetify.breakpoint.mobile) {
      window.open(urlObject.mobileGameUrl);
    } else {
      window.open(urlObject.desktopGameUrl);
    }
  }
}
