var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('v-container',{staticStyle:{"max-width":"1200px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('center',[_c('h1',{staticClass:"amber--text"},[_vm._v(" รายละเอียดผู้เล่น ")]),_c('span',{staticClass:"white--text"},[_vm._v(" สามารถทำการค้นข้อมูลผู้เล่น BETFLIKBNK ได้ด้านล่างนี้ ")])])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{attrs:{"tile":"","color":"red"}},[_c('v-card-text',[_c('v-row',{staticClass:"ma-0"},[_c('span',{staticClass:"white--text"},[_vm._v(" เครดิต ")]),_c('v-spacer'),_c('profile-connector',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var profile = ref.profile;
return [_c('span',{staticClass:"white--text"},[_vm._v(" ฿ "+_vm._s(profile.balance.toFixed(2))+" ")])]}}])})],1)],1),_c('v-card-actions',{staticClass:"px-0 pb-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"border-right":"1px solid rgba(0,0,0,.12)","border-top":"1px solid rgba(0,0,0,.12)"},attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"to":{ name: 'Deposit' },"block":"","depressed":"","tile":"","color":"red","dark":""}},[_vm._v(" ฝากเงิน ")])],1),_c('v-col',{staticStyle:{"border-top":"1px solid rgba(0,0,0,.12)"},attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"to":{ name: 'Withdraw' },"block":"","depressed":"","tile":"","dark":"","color":"red"}},[_vm._v(" ถอนเงิน ")])],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('div',{staticClass:"d-flex justify-center pa-5 red"},[_c('h3',{staticClass:"white--text font-weight-regular"},[_vm._v("เมนูหลัก")])]),_c('div',{staticClass:"pa-5"},[_c('v-row',_vm._l((_vm.menus),function(menu){return _c('v-col',{key:menu.name,attrs:{"cols":"4","sm":"3"}},[(!!menu.dialogName)?_c('dialog-connector',{scopedSlots:_vm._u([{key:menu.dialogName,fn:function(ref){
var openDialog = ref.openDialog;
return [_c('base-menu-card',{staticClass:"elevation-10",on:{"click":function($event){return openDialog(menu.dialogName)}}},[_c('div',{staticClass:"pa-2"},[_c('center',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(menu.icon))]),_c('br'),_c('span',{staticStyle:{"font-size":".75rem"}},[_vm._v(_vm._s(menu.name))])],1)],1)])]}}],null,true)}):(menu.name === 'เล่นเกมส์')?_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var gameUrl = ref.gameUrl;
return [_c('profile-connector',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var profile = ref.profile;
return [_c('base-menu-card',{staticClass:"elevation-10",attrs:{"disabled":profile.balance < 10},on:{"click":function($event){profile.balance < 10 ? _vm.notifyPlayerToTopUp() : _vm.playGame(gameUrl)}}},[_c('div',{staticClass:"pa-2"},[_c('center',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(menu.icon))]),_c('br'),_c('span',{staticStyle:{"font-size":".75rem"}},[_vm._v(_vm._s(menu.name))])],1)],1)])]}}],null,true)})]}}],null,true)}):(menu.name === 'ออกจากระบบ')?_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var logout = ref.logout;
return [_c('base-menu-card',{staticClass:"elevation-10",on:{"click":function($event){return logout()}}},[_c('div',{staticClass:"pa-2"},[_c('center',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(menu.icon))]),_c('br'),_c('span',{staticStyle:{"font-size":".75rem"}},[_vm._v(_vm._s(menu.name))])],1)],1)])]}}],null,true)}):_c('base-menu-card',{staticClass:"elevation-10"},[_c('div',{staticClass:"pa-2"},[_c('center',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(menu.icon))]),_c('br'),_c('span',{staticStyle:{"font-size":".75rem"}},[_vm._v(_vm._s(menu.name))])],1)],1)])],1)}),1)],1),_c('div',{staticClass:"d-flex justify-center pa-5 amber"},[_c('h3',{staticClass:"white--text font-weight-regular"},[_vm._v("เกมส์ภายใน")])]),_c('div',{staticClass:"pa-5"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4","sm":"3"}},[_c('dialog-connector',{scopedSlots:_vm._u([{key:"FortuneWheelDialog",fn:function(ref){
var openDialog = ref.openDialog;
return [_c('base-menu-card',{staticClass:"elevation-10",on:{"click":function($event){return openDialog('FortuneWheelDialog')}}},[_c('div',{staticClass:"pa-2"},[_c('center',[_c('v-icon',{attrs:{"color":"amber"}},[_vm._v("fas fa-dharmachakra")]),_c('br'),_c('span',{staticStyle:{"font-size":".75rem"}},[_vm._v("กงล้อเสี่ยงโชค")])],1)],1)])]}}])})],1)],1)],1)])],1)],1)],1),_c('dialog-connector',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var possibleDialog = ref.possibleDialog;
return [_c('dialog-connector',{scopedSlots:_vm._u([_vm._l((possibleDialog),function(dialog){return {key:dialog,fn:function(ref){
var open = ref.open;
var closeDialog = ref.closeDialog;
var dialogType = ref.dialogType;
return [(dialogType === dialog)?_c(dialog,{key:dialog,tag:"component",attrs:{"open":open},on:{"close-dialog":function($event){return closeDialog()}}}):_vm._e()]}}})],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }