














import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Dialog = namespace('Dialog');

@Component
export default class DialogConnector extends Vue {
  @Dialog.State
  public dialogType!: string;

  @Dialog.State
  public open!: boolean;

  @Dialog.Action
  public updateDialog!: (payload: { open: boolean; dialogType: string }) => {}

  public openDialog(dialogType: string) {
    this.updateDialog({ open: true, dialogType });
  }

  public closeDialog() {
    this.updateDialog({ open: true, dialogType: '' });
  }

  // eslint-disable-next-line class-methods-use-this
  get possibleDialog() {
    return [
      'ProfileDialog',
      'ChangePasswordDialog',
      'TransactionDialog',
      'RspGameDialog',
      'BetHistoryDialog',
      'FortuneWheelDialog',
    ];
  }
}
